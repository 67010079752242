<template>
  <div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 4px;
      "
      class="m-b-24"
    >
      <el-input
        v-model="search"
        placeholder="Поиск"
        clearable
        @clear="handleCleanSearch"
        @keyup.enter.native="handleCleanSearch"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          style="
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="handleFilterEntities"
        />
      </el-input>
    </div>
    <div class="m-b-16">
      <iq-check-box
        v-if="entriesFilter.length > 0"
        v-model="selectAll"
        title="Выбрать все"
      />

      <check-group
        v-model="statuses"
        name="id"
        alignment="centered"
        :block="true"
        :entries="entriesFilter"
      />
    </div>
  </div>
</template>
<script>
import CheckGroup from '@/views/manager/components/mobile/components/modal/CheckGroup'
import IqCheckBox from '@/views/manager/components/mobile/components/modal/IqCheckBox'

export default {
  components: { IqCheckBox, CheckGroup },

  props: {
    entries: {
      type: Array,
      default: () => [],
    },
    chosenEntities: {
      type: Array,
      default: () => [],
    },
    titleSection: {
      type: String,
      default: 'test title',
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      search: '',
      isSearch: false,
      statuses: [],
      filterEntries: [],
    }
  },
  computed: {
    entriesFilter() {
      return this.isSearch ? this.filterEntries : this.entries
    },
    chosenStatuses() {
      const chosen = this.entries.filter(
        item => this.statuses.indexOf(item.value) > -1,
      )

      this.$emit('handleChangeFilter', chosen)

      return chosen
    },
    selectAll: {
      get() {
        return this.statuses.length === this.entries.length
      },
      set(value) {
        this.statuses = []

        if (value) {
          this.entries.forEach(select => {
            this.statuses.push(select.value)
          })
        }
      },
    }, // END sele
  },
  watch: {
    search(value) {
      if (value.length === 0) {
        this.isSearch = false
      }
    },
    statuses: {
      handler(value) {
        let arr = this.entries.filter(item => value.indexOf(item.value) > -1)

        if (this.type) {
          this.$emit('handleChangeFilter', arr, this.type)
        }
      },
    },
  },
  mounted() {
    this.chosenEntities.forEach(select => {
      this.statuses.push(select.value)
    })
  },
  methods: {
    handleDeleteFilter(id) {
      if (id === null) {
        this.statuses = []
      }
      if (id) {
        this.statuses = this.statuses.filter(item => item !== id)
      }
    },
    openModal(id) {
      this.$emit('open', id)
    },
    handleFilterEntities() {
      if (this.search.length > 0) {
        this.isSearch = true
        this.filterEntries = this.entries.filter(item =>
          item.label.includes(this.search),
        )
      }
    },
    handleCleanSearch() {
      this.isSearch = false

      this.filterEntries = []
      this.search = ''
    },
  },
}
</script>

<style lang="sass" scoped></style>
