<template>
  <div>
    <div v-for="entry in entries" :key="entry.value">
      <div style="padding: 4px" class="m-b-24">
        <p-check
          v-model="model"
          :name="name"
          :value="entry.value"
          class="p-svg p-curve p-bigger"
          color="info"
          style="font-size: 20px"
        >
          <!-- svg path -->
          <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
            <path
              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
              style="stroke: white; fill: white"
            />
          </svg>
          <span style="font-size: 14px">{{ entry.label }}</span>
        </p-check>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxGroup',
  props: {
    name: String,
    entries: Array,
    value: Array,
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
}
</script>
