var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.entries, function (entry) {
      return _c("div", { key: entry.value }, [
        _c(
          "div",
          { staticClass: "m-b-24", staticStyle: { padding: "4px" } },
          [
            _c(
              "p-check",
              {
                staticClass: "p-svg p-curve p-bigger",
                staticStyle: { "font-size": "20px" },
                attrs: { name: _vm.name, value: entry.value, color: "info" },
                model: {
                  value: _vm.model,
                  callback: function ($$v) {
                    _vm.model = $$v
                  },
                  expression: "model",
                },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "svg svg-icon",
                    attrs: { slot: "extra", viewBox: "0 0 20 20" },
                    slot: "extra",
                  },
                  [
                    _c("path", {
                      staticStyle: { stroke: "white", fill: "white" },
                      attrs: {
                        d: "M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z",
                      },
                    }),
                  ]
                ),
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v(_vm._s(entry.label)),
                ]),
              ]
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }